import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";
import RestDataSource from "./restdatasource";

export const ProductService = {
  ProductByName,
  ProductById,
  addProduct,
  productIamLookingFor,
  productLevel,
  updateProduct,
  addModules,
  updateModules,
  deleteIsactiveInactive,
  fetchProductPreRequisiteByParentProduct,
  addLearningPath,
  fetchProductAllExceptMappedProduct,
  updateIsactiveInactiveModuleList,
  addProductImageVideo,
  filterstudents,
  updategdpiStatus,
  updateLearningPath,
  fetchLearningPathParentProductID,
  fetchLearningPathParentProductIDAssignUser,
  fetchProductDetailsByPartyId,
  bulkupdateuserstatus,
  fetchUnassignedUserDetails,
  getProgressOverview,
  fetchModulesData,
  checkSequenceDuplication,
  //fetchProductTypeData
  fetchAllActiveVideo,
  getAllSubscribedVideos,
  fetchAllCategoryByLevel,
  subscribeToVideo,
  fetchPartyWishlist,
  fetchPartyCart,
  addVideoToWishlist,
  deleteVideoFromWishlist,
  deleteVideoFromCart,
  deleteProductFromCart,
  fetchAllActiveCourse,
  fetchJobsAsPerKeyskills,
  fetchJobs,
  applyForJob,
  fetchAppliedJobs,
  fetchAppliedCouponDetails,
  fetchAllCoupons,
  fetchPartyCartCount,
  fetchPartyWishlistCount,
  fetchJobQuestions,
  getSimilarJobs,
  getRecomCourse,
  getRejectionReasons,
  getJobFeedback,
  getJobLocation,
  storeCandidateCTAResponse,
  storePartyEvent,
  interestedProductKotak,
  getProductDetailById,
  getProductValidityDetails,
  saveProductValidityDetails,
  getStudentProgress,
  saveJobApplyClick,
  getTypeOfCourseList,
  fetchCourseQuestions,
  fetchAppliedCourses,
  applyForCourse,
  getProductIdFromSourceParam,
  getAppliedJobLocation,
  getAddOnCourseList,
  getSelectedAddOnCourseList,
  allChildCourseList,
  checkIfChildOrParentExists,
  getParentMaxPrice,
  updateAddOnCourse,
  updateCategoryMap,
  getShortCoursesList,
  updateShortCourses,
};


function getStudentProgress(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_USER_PROGRESS;
  return new RestDataSource(url, fnError).Store(
    data,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );

}

function getProductDetailById(getProductDetailById, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_DETAIL_BY_ID;
      // var url = "http://192.168.71.113:8059" + Global_var.URL_PRODUCT_DETAIL_BY_ID;
      return new RestDataSource(url, fnError).GetOneByParam(
        getProductDetailById,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function ProductByName(ProductByName, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BYNAME;
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).GetOneByParam(
        ProductByName,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function ProductById(ProductById, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BYID;
      //var mobilevalidat = JSON.stringify(ProductByName);
      return new RestDataSource(url, fnError).GetOneByParam(
        ProductById,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function fetchProductPreRequisiteByParentProduct(ProductById, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_PRE_REQUISITE;
  //var mobilevalidat = JSON.stringify(ProductByName);
  return new RestDataSource(url, fnError).GetOneByParam(ProductById, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function fetchProductAllExceptMappedProduct(ProductById, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_ALL_EXCEPT_MAPPED;
  //var mobilevalidat = JSON.stringify(ProductByName);
  return new RestDataSource(url, fnError).Store(ProductById, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function fetchProductDetailsByPartyId(id, fn, fnError) {
  // LoginService.TokenUser((res) => {
  //   if (res.status === "success") {
  //     localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_BY_PARTYID;
      return new RestDataSource(url, (err) => fnError(err)).GetOneByParam(
        id,
        (res) => {
          if (res.data.status === "success") {
            if (res != null) {
              if (res.headers["jtitoken"] != null) {
                localStorage.setItem("jti-token", res.headers.jtitoken);
                fn(res.data);
              } else {
                fnError(res.data.reasonText);
              }
            }
          }
        }
      );
  //   }
  // });
}
function addProduct(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    //console.log("SERVICE", res);
    if (res.status === "success") {
      //console.log("RES.STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function productIamLookingFor(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_IAM_LOOKING_FOR;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function productLevel(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PRODUCT_LEVEL;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function addProductImageVideo(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT_THUMBNAIL_VIDEO;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function checkSequenceDuplication(ProductSequenceNo, fn, fnError) {
  //LoginService.TokenUser(res => {
  //if (res.status === "success") {
  //localStorage.setItem("jwt-token", res.responseObject);
  let url =
    Global_var.BASEURL + Global_var.URL_CHECK_PRODUCT_SEQUENCE_DUPLICATE;
  return new RestDataSource(url, fnError).GetOneByParam(
    ProductSequenceNo,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
  //}
  //}, fnError);
}

// function editProduct(data, fn, fnError) {
//   LoginService.TokenUser(res => {
//     if (res.status === "success") {
//       //console.log("RES>STATUS", res.status);
//       localStorage.setItem("jwt-token", res.responseObject);
//       let url = Global_var.BASEURL + Global_var.URL_ADD_PRODUCT;
//       return new RestDataSource(url, fnError).Update(data, res => {
//         if (res != null) {
//           if (res.headers["jtitoken"] != null) {
//             localStorage.setItem("jti-token", res.headers.jtitoken);
//           }
//           fn(res.data);
//         }
//       });
//     }
//   }, fnError);
// }

function updateProduct(user, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEPRODUCT;
      //var url = Global_var.URL_UPDATEUSERPROFILE;

      return new RestDataSource(url, fnError).Update(user, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function filterstudents(statusparameter, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GDPI_STUDENT;
      //var url = Global_var.URL_UPDATEUSERPROFILE;

      return new RestDataSource(url, fnError).GetOneByParam(
        statusparameter,
        (response) => {
          if (res != null) {
            // if (res.headers["jtitoken"] != null) {
            //   localStorage.setItem("jti-token", res.headers.jtitoken);
            // }
            fn(response.data);
          }
        }
      );
    }
  });
}

function updategdpiStatus(GDPIDATA, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GDPI_BULKUPLOAD;
      //var url = Global_var.URL_UPDATEUSERPROFILE;

      return new RestDataSource(url, fnError).Update(GDPIDATA, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function bulkupdateuserstatus(GDPIDATA, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_BULK_USER_STATUS_CHANGE;

      return new RestDataSource(url, fnError).Update(GDPIDATA, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}
function addModules(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    //console.log("SERVICE", res);
    if (res.status === "success") {
      //console.log("RES.STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_MODULES;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function addLearningPath(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      //console.log("RES.STATUS", res.status);
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_ADD_LEARNINGPATH;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

function updateModules(user, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEMODULES;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).Update(user, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function deleteIsactiveInactive(productIDIsActive, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATEISACTIVEINACTIVE;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).DeleteOneByParam(
        productIDIsActive,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  });
}

function updateIsactiveInactiveModuleList(user, fn, fnError) {
  //

  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url =
        Global_var.BASEURL + Global_var.URL_UPDATEISACTIVEINACTIVEMODULE;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).Update(user, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function updateLearningPath(user, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATELEARNINGPATH;
      //var url = Global_var.URL_UPDATEUSERPROFILE;
      return new RestDataSource(url, fnError).Update(user, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}

function fetchLearningPathParentProductID(parentIdparameter, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PARENTPRODUCTID;
      return new RestDataSource(url, fnError).GetOneByParam(
        parentIdparameter,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function fetchLearningPathParentProductIDAssignUser(
  parentIdparameter,
  fn,
  fnError
) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PARENTPRODUCTIDASSIGNUSER;
      return new RestDataSource(url, fnError).GetOneByParam(
        parentIdparameter,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function fetchUnassignedUserDetails(ProductIDParameter, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_USERLIST_BY_PRODUCT_ID;
      return new RestDataSource(url, fnError).GetOneByParam(
        ProductIDParameter,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function getProgressOverview(studentPartyId, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_GET_PROGRESS_OVERVIEW;
      return new RestDataSource(url, fnError).GetOneByParam(
        studentPartyId,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}

function fetchModulesData(param, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);

      var url = Global_var.BASEURL + Global_var.URL_GETMODULESBYID;
      return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

// ! Videos
function getAllSubscribedVideos(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_VIDEOS}`;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Videos
function fetchAllActiveVideo(param, data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_VIDEOS}?${param}`;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Categories
function fetchAllCategoryByLevel(param, data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_CATEGORY}?${param}`;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Subscribe to video
function subscribeToVideo(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_SUBSCRIBE;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

// ! party Wishlist
function fetchPartyWishlist(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_WISHLIST}`;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! party Cart
function fetchPartyCart(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_CART}`;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Wishlist to video
function addVideoToWishlist(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_WISHLIST;
      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
// ! Wishlist to video
function deleteVideoFromWishlist(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_WISHLIST;
      return new RestDataSource(url, fnError).Delete(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
// ! Delete video from cart
function deleteVideoFromCart(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_SUBSCRIBE;
      return new RestDataSource(url, fnError).Delete(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
// ! Delete product from cart
function deleteProductFromCart(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_CART;
      return new RestDataSource(url, fnError).Delete(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
// ! Courses New
function fetchAllActiveCourse(param, data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_ALL_COURSE}?${param}`;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Jobs From Keyskills
function fetchJobsAsPerKeyskills(param, data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_JOBS_FROM_KEYSKILL}?${param}`;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Jobs
function fetchJobs(param, data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_JOBS}?${param}`;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Apply for Job
function applyForJob(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_APPLY_JOB;

      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}

// ! fetch Applied Jobs
function fetchAppliedJobs(param, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_APPLIED_JOBS;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! fetch Applied Jobs
function fetchAppliedCouponDetails(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_DISCOUNT_CODE;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function fetchAllCoupons(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_ALL_DISCOUNT_CODE;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! party Cart
function fetchPartyCartCount(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_CART_COUNT}`;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
} // ! party Wishlist
function fetchPartyWishlistCount(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_WISHLIST_COUNT}`;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch job Question
function fetchJobQuestions(param, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_JOBS_QUESTIONS}`;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Similar Job
function getSimilarJobs(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SIMILAR_JOBS;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getRecomCourse(param, data, fn, fnError) {
  var url = `${Global_var.BASEURL}${Global_var.URL_RECOMMENDED_COURSE}?${param}`;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Rejection Reasons
function getRejectionReasons(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_REJECTION_REASONS;

  return new RestDataSource(url, fnError).GetData((res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Job Feedback
function getJobFeedback(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_JOBS_FEEDBACK;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Save User CTA Response
function storeCandidateCTAResponse(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_CTA_RESPONSE;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Save Event to Calander
function storePartyEvent(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_CALENDER_EVENTS;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Save Interested Product of Kotak
function interestedProductKotak(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_INTERESTED_PRODUCT_KOTAK;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! Get Produdct Validity Details
function getProductValidityDetails(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_PRODUCT_VALIDITY_DETAILS;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

// ! SAVE Produdct Validity Details
function saveProductValidityDetails(data, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_PRODUCT_VALIDITY;

  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Fetch Job Locations
function getJobLocation(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_JOBS_LOCATION;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
//! Job Apply Btn Click
function saveJobApplyClick(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_JOB_APPLY_CLICK;
  return new RestDataSource(url, fnError).Store(
    data,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
function getTypeOfCourseList(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_TYPE_OF_COURSE_LIST;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getAddOnCourseList(param,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_ADD_ON_COURSE_LIST;
  // var url = "http://192.168.0.116:8059"+ Global_var.URL_GET_ADD_ON_COURSE_LIST;
  // var url = "http://192.168.71.113:8059"+ Global_var.URL_GET_ADD_ON_COURSE_LIST;
  return new RestDataSource(url, fnError).GetOneByParam(param,res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getSelectedAddOnCourseList(param,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SELECTED_ADD_ON_COURSE_LIST;
  // var url = "http://192.168.71.113:8059"+ Global_var.URL_GET_SELECTED_ADD_ON_COURSE_LIST;
  
  return new RestDataSource(url, fnError).GetOneByParam(param,res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function allChildCourseList(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ALL_CHILD_COURSE_LIST;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function checkIfChildOrParentExists(param,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CHECK_PARENT_CHILD_EXISTS;
  // var url = "http://192.168.71.113:8059"+ Global_var.URL_CHECK_PARENT_CHILD_EXISTS;
  return new RestDataSource(url, fnError).GetOneByParam(param,res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function updateAddOnCourse(params,data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_UPDATE_ADD_ON_COURSE;
      // var url ="http://192.168.71.113:8059"+ Global_var.URL_UPDATE_ADD_ON_COURSE;
      return new RestDataSource(url, fnError).UpdateOneByParam(params,data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  });
}
function updateCategoryMap(params,data, fn, fnError) {  
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_CATEGORY_MAP;
  return new RestDataSource(url, fnError).UpdateOneByParam(params,data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getParentMaxPrice(param,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_PARENT_MAX_PRICE;
  // var url = "http://192.168.71.113:8059"+ Global_var.URL_GET_PARENT_MAX_PRICE;
  return new RestDataSource(url, fnError).GetOneByParam(param,res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
  function fetchCourseQuestions(param, fn, fnError) {
    var url = `${Global_var.BASEURL}${Global_var.URL_COURSE_QUESTIONS}`;
  
    return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    });
  }
    // ! fetch Applied Courses
function fetchAppliedCourses(param, fn, fnError) {
  let url = Global_var.BASEURL + Global_var.URL_APPLIED_JOBS;

  return new RestDataSource(url, fnError).GetOneByParam(param, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// ! Apply for Course
function applyForCourse(data, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_APPLY_COURSE;

      return new RestDataSource(url, fnError).Store(data, (res) => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          fn(res.data);
        }
      });
    }
  }, fnError);
}
function getProductIdFromSourceParam(getProductIdFromSourceParam, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_PRODUCT_ID_FROM_SOURCE_PARAM;
      return new RestDataSource(url, fnError).GetOneByParam(
        getProductIdFromSourceParam,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  }, fnError);
}
// ! Fetch Applied Job Locations
function getAppliedJobLocation(data, fn, fnError) {
  // var url = "http://192.168.71.63:8008/" + Global_var.URL_JOBS_APPLIED_LOCATION;
  var url = Global_var.BASEURL+ Global_var.URL_JOBS_APPLIED_LOCATION;

  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getShortCoursesList(params,fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SHORT_COURSES;
  return new RestDataSource(url, fnError).GetOneByParam(
    params,
    (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
function updateShortCourses(params,data, fn, fnError) {  
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_SHORT_COURSES;
  return new RestDataSource(url, fnError).UpdateOneByParam(params,data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
