import Axios from 'axios';
import { EncryptionService } from "../components/EncryptData";
import { Global_var } from '../global/global_var';
// import jwt_decode from "jwt-decode";
// import { LoginService } from './login.service';
// import { axiosheaders } from './header';
// import { fetchStudentError } from '../action/studentAction';
// import ErrorBoundary from '../components/errorboundary/errorboundary';

export default class RestDataSource {
  constructor(base_url, errorCallback) {
    const jtiToken = localStorage.getItem('jti-token');
    const jwtToken = localStorage.getItem('jwt-token');
    const currentPath = window.location.pathname;
    if(Global_var.SECURITY_CHANGE==="true"){
    console.log("Vamsi restdatasource jti-token')",localStorage.getItem('jti-token'))
    console.log("Vamsi restdatasource 'jwt-token')",localStorage.getItem('jwt-token'))
    //Axios.defaults.headers.common['tokentype'] = localStorage.getItem('TokenName') === null ? 'jti' : localStorage.getItem('TokenName');
    console.log("Vamsi Rest if ",!jtiToken , !jwtToken,["",null,undefined,"null","undefined"].includes(jtiToken) , ["",null,undefined,"null","undefined"].includes(jwtToken))
    
    if (["",null,undefined,"null","undefined"].includes(jtiToken) || ["",null,undefined,"null","undefined"].includes(jwtToken)) {
      const params = new URLSearchParams(window.location.search);
      const sessionTimeout = params.get('sessiontimeout');
    
      console.log("Vamsi Rest params", sessionTimeout, params, window.location.pathname,jtiToken,jwtToken,currentPath);
          if (currentPath !== '/') {
            setTimeout(() => {
             if(currentPath!=="/unsubscribe-page" && currentPath !== "/register"){
              localStorage.clear();
              window.location.href = "/?sessiontimeout=true";
            }
          }, 200);
        }
    }
  }  
  const sessionStorageEmail=sessionStorage.getItem("eMail");
  const localStorageEmail=localStorage.getItem("eMail");
if (!["",null,undefined,"null","undefined"].includes(sessionStorageEmail) && !["",null,undefined,"null","undefined"].includes(localStorageEmail)) {
if(sessionStorageEmail!==localStorageEmail){
  if (currentPath !== '/') {
    setTimeout(() => {
      if(currentPath!=="/unsubscribe-page" && currentPath !== "/register"){
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/?sessiontimeout=true";
      }
    }, 200);
    return;
  }
}
}
    Axios.defaults.headers.common['jtitoken'] = localStorage.getItem('jti-token');
    Axios.defaults.headers.common['token'] = localStorage.getItem('jwt-token');
    Axios.defaults.headers.common['tokentype'] = localStorage.getItem('TokenName');
    if (localStorage.getItem('userid') !== '' && localStorage.getItem('userid') !== null) {
      Axios.defaults.headers.common['userId'] = JSON.parse(localStorage.getItem('userid'));
      Axios.defaults.headers.common['userlogin'] = localStorage.getItem('eMail');
    } else {
      Axios.defaults.headers.common['userId'] = 2;
      Axios.defaults.headers.common['userlogin'] = 'pramila@gmail.com';

    }
    this.BASE_URL = base_url;
    this.handleError = errorCallback;
  }
  
  async GetData(callback) {
    this.SendRequest('get', this.BASE_URL, callback);
  }
  async GetOneByParam(id, callback) {
    this.SendRequest('get', `${this.BASE_URL}?${id}`, callback);
  }
  async GetOne(id, callback) {
    this.SendRequest('get', `${this.BASE_URL}/${id}`, callback);
  }
  async Store(data, callback) {
    this.SendRequest('post', this.BASE_URL, callback, data);
  }
  async Update(data, callback) {
    this.SendRequest('put', this.BASE_URL, callback, data);
  }
  async UpdateOneByParam(id,data,callback) {
    this.SendRequest('put', `${this.BASE_URL}?${id}`, callback, data);
  }
  async Delete(data, callback) {
    this.SendRequest('delete', this.BASE_URL, callback, data);
  }

  async DeleteOneByParam(id, callback) {
    this.SendRequest('delete', `${this.BASE_URL}?${id}`, callback);
  }
  async SendRequest(method, url, callback, data) {
    try {
      console.log("encrypt url ", url)
      
      // if (url.includes("finx-fc-payment")) {
        // console.log("encrypt The string 'finx-fc-payment' exists in the URL.");
      if(Global_var.SECURITY_CHANGE === "true") {
      console.log("encrypt before", url, data)
      let paramsIndex = url.indexOf('?');
      // console.log("paramsIndex value", paramsIndex)
      if (paramsIndex === -1) {
        // console.log("paramsIndex if", paramsIndex)
      }
      else {
        // console.log("paramsIndex else", paramsIndex)
        let baseUrl = url.slice(0, paramsIndex + 1);
        let paramsString = url.slice(paramsIndex + 1);
        // console.log('baseUrl',baseUrl);
        // console.log('paramsString',paramsString);
        if (![null, undefined, ""].includes(paramsString)) {
          url = baseUrl + EncryptionService.HandleEncrypt(paramsString, "params");
        }
        else {
          url = baseUrl + paramsString;
        }

      }
      // if (["post", "put", "delete"].includes(method)) {
      //   const fieldNames =
      //     [
      //       "studentPartyID",
      //       "partyId",
      //       "emailID",
      //       "email",
      //       "mobileNumber",
      //       "mobileNo",
      //       "panNumber",
      //       "studentFatherPanNumber",
      //       "studentFatherEmailID",
      //       "studentFatherMobileNo",
      //       "studentMotherPanNumber",
      //       "studentMotherEmailID",
      //       "studentMotherMobileNo",
      //       "contactNo",
      //       "secondaryEmailID",
      //       "adminPartyID",
      //     ];
      //   fieldNames.forEach(fieldName => {
      //     const matchingKey = Object.keys(data).find(key => key.toLowerCase() === fieldName.toLowerCase());
      //     if (matchingKey) {
      //       const encryptedValue = EncryptionService.HandleEncrypt(data[matchingKey]);
      //       console.log('encryptedValue',encryptedValue,matchingKey);
      //       data[matchingKey] = encryptedValue;
      //     }
      //   });
      // }
      function encryptFields(data) {
        const fieldNames = [
          "studentPartyID",
          "partyId",
          "emailID",
          "email",
          "mobileNumber",
          "mobileNo",
          "panNumber",
          "studentFatherPanNumber",
          "studentFatherEmailID",
          "studentFatherMobileNo",
          "studentMotherPanNumber",
          "studentMotherEmailID",
          "studentMotherMobileNo",
          "contactNo",
          "secondaryEmailID",
          "adminPartyID",
        ];
      
        function encryptObjectFields(obj) {
          if (Array.isArray(obj)) {
            obj.forEach(item => encryptObjectFields(item));
          } else if (obj !== null && typeof obj === 'object') {
            Object.keys(obj).forEach(key => {
              const matchingKey = fieldNames.find(fieldName => fieldName.toLowerCase() === key.toLowerCase());
              if (matchingKey) {
                const encryptedValue = EncryptionService.HandleEncrypt(obj[key]);
                console.log('encryptedValue', encryptedValue, key);
                obj[key] = encryptedValue;
              }
              // Recurse into nested objects
              if (obj[key] !== null && typeof obj[key] === 'object') {
                encryptObjectFields(obj[key]);
              }
            });
          }
        }
      
        encryptObjectFields(data);
      }
      let SkippedURL=`${Global_var.BASEURL}/finx-thor-usermanagement/api/v1/user/getFilteredUser`
      // Example usage:
      if (["post", "put", "delete"].includes(method)&&!(url===SkippedURL)) {
        encryptFields(data);
      }
      
      }
       //!!Whole Data Object Encryption Code
      //   console.log("type",url,data,typeof data,data?.length);
      //   if((![null,undefined,""].includes(data))||(typeof data === "object")){
      //     data=EncryptionService.HandleEncrypt(data,"data")
      //   }
      // let headers = { ...Axios.defaults.headers.common };
      // if (method === "post") { 
      //   headers['Content-Type']= 'application/json;charset=UTF-8'
      //   const contentLength = new Blob([data]).size;
      //    headers['Content-Length'] = parseInt(contentLength);
      // }
      //  console.log("Request headers:", JSON.stringify(headers));

      // console.log("encrypt after url", url)
      // console.log("encrypt after data", data)
      let response = await Axios.request({
        //headers: axiosheaders.Headers(),
        method: method,
        url: url,
        data: data
      });
      callback(response);
    } catch (err) {

      console.log(err);
      // this.handleError(err);
    }
  }
}
