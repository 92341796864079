import { Component } from 'react';
import { LoginService } from "../services/login.service";
import { Global_var } from "../global/global_var";

class GlobalClickComponent extends Component {
  constructor(props) {
    super(props);
    this.disabledButtons = new Set();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalButtonClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalButtonClick, true);
  }

  handleGlobalButtonClick = (event) => {
    const delay = 2500;
    if (event.target.tagName.toUpperCase() === 'BUTTON'&& event.target.getAttribute('type') === 'submit') {
      const button = event.target;
      if (this.disabledButtons.has(button)) {
        event.stopPropagation();
        event.preventDefault();
        console.log('VamsiD: Double click of Button detected. Ignored.',button);
      } else {
        this.disableButton(button, delay);
      }
    }
    if(Global_var.SECURITY_CHANGE==="true"){
      const currentPath = window.location.pathname;
      console.log("Vamsi Global jti-token')",localStorage.getItem('jti-token'))
      console.log("Vamsi Global 'jwt-token')",localStorage.getItem('jwt-token'))
  
      const jtiToken = localStorage.getItem('jti-token');
      const jwtToken = localStorage.getItem('jwt-token');
      console.log("Vamsi Global if ",!jtiToken , !jwtToken,["",null,undefined,"null","undefined"].includes(jtiToken) , ["",null,undefined,"null","undefined"].includes(jwtToken))
      
      if (["",null,undefined,"null","undefined"].includes(jtiToken) || ["",null,undefined,"null","undefined"].includes(jwtToken)) {
    if(currentPath === '/'){
      LoginService.TokenUser(res => {
        localStorage.setItem('jwt-token', res.responseObject);
        localStorage.setItem('jti-token', res.responseObject);
      })
    }
  }
}
  };

  disableButton = (button, delay) => {
    this.disabledButtons.add(button);
    button.style.opacity = '0.5';
    button.style.pointerEvents = 'none';
    console.log('VamsiD: Button',button, 'disabled for', delay, 'ms.');

    setTimeout(() => {
      button.style.opacity = '';
      button.style.pointerEvents = ''; 
      this.disabledButtons.delete(button);
      console.log('VamsiD: Button re-enabled.',button);
    }, delay);
  };

  render() {
    return null; 
  }
}

export default GlobalClickComponent;
